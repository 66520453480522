import ToolTip from '@/Components/ToolTip';

export default function Features({
	commentated,
	stewards,
	liveStreamed,
	prizes
}) {
	return (
		<>
			<div className="divider">Features</div>

			<div className="flex justify-center gap-6 mb-4">
				<ToolTip tip="Commentary">
					<span
						className={`material-icons-outlined text-4xl ${
							commentated
								? 'text-gray-400'
								: 'text-gray-700'
						}`}
					>
						chat
					</span>
				</ToolTip>
				<ToolTip tip="Live Stewarding">
					<span
						className={`material-icons-outlined text-4xl ${
							stewards
								? 'text-gray-400'
								: 'text-gray-700'
						}`}
					>
						flag
					</span>
				</ToolTip>
				<ToolTip tip="Broadcast">
					<span
						className={`material-icons-outlined text-4xl ${
							liveStreamed
								? 'text-gray-400'
								: 'text-gray-700'
						}`}
					>
						tv
					</span>
				</ToolTip>
				<ToolTip tip="Prizes">
					<span
						className={`material-icons-outlined text-4xl ${
							prizes
								? 'text-gray-400'
								: 'text-gray-700'
						}`}
					>
						monetization_on
					</span>
				</ToolTip>
			</div>
		</>
	);
}
